exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-equipo-tsx": () => import("./../../../src/pages/equipo.tsx" /* webpackChunkName: "component---src-pages-equipo-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-gracias-tsx": () => import("./../../../src/pages/gracias.tsx" /* webpackChunkName: "component---src-pages-gracias-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-templates-blog-post-page-tsx": () => import("./../../../src/templates/BlogPostPage.tsx" /* webpackChunkName: "component---src-templates-blog-post-page-tsx" */),
  "component---src-templates-team-member-page-tsx": () => import("./../../../src/templates/TeamMemberPage.tsx" /* webpackChunkName: "component---src-templates-team-member-page-tsx" */)
}

